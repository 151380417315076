import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import Icons from "../Icons";
import StatusDropdown from "../Dropdown/StatusDropdown";
import { Tooltip, Typography } from "@mui/material";
import SubjectCodeDropDown from "../Dropdown/SubjectCodeDropDown";
import StudentDetailsDrawer from "./studentDetailsDrawer";
import ToastMessage from "../ToastMessage";
import { resetStudentAcc } from "../../services/api/login";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

type Student = {
  id: string;
  mst_student_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  access_start_date: string | null;
  access_end_date: string | null;
  access_status: boolean;
  campus_id: string;
  enrolmentStatus?: string;
  SubjectCode?: string[];
};

type TableData = {
  subject_name?: string;
  students: Student[];
};

interface StudentTDataProps {
  stuData: TableData[];
  loading: boolean;
  tableLoading?: any;
  rowCountState?: any;
  paginationModel?: any;
  setPaginationModel?: any;
}

const StudentTData: React.FC<StudentTDataProps> = ({
  stuData,
  loading,
  tableLoading,
  rowCountState,
  paginationModel,
  setPaginationModel,
}: StudentTDataProps) => {
  const verifyAccessString = Cookies.get("authAccess");
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const token = verifyAccess.token;
  const [toast, setToast] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const [drawer, setDrawer] = useState(false);
  const [paramID, setParamID] = useState<null | number>(null);
  const [loadingRows, setLoadingRows] = useState<Set<string>>(new Set());
  const [disabledRows, setDisabledRows] = useState<Set<string>>(new Set());

  const handleResetStudent = async (email: string, rowId: string) => {
    // Show confirmation dialog
    const confirmed = window.confirm(
      "Are you sure you want to reset the password for this student?"
    );
  
    if (!confirmed) return; // Exit if user cancels
  
    // Add rowId to loadingRows to show loader for that specific row
    setLoadingRows((prev) => new Set(prev).add(rowId));
  
    try {
      // Simulate API call
      await resetStudentAcc({ email: email }, token);
  
      // Optionally disable the button for 5 minutes
      setDisabledRows((prev) => new Set(prev).add(rowId));
      setTimeout(() => {
        setDisabledRows((prev) => {
          const updated = new Set(prev);
          updated.delete(rowId);
          return updated;
        });
      }, 300000); // 5 minutes
    } catch (error) {
      console.error("Error resetting student:", error);
    } finally {
      // Remove rowId from loadingRows after API call
      setLoadingRows((prev) => {
        const updated = new Set(prev);
        updated.delete(rowId);
        return updated;
      });
    }
  };
  const columns: GridColDef[] = [
    {
      field: "first_name",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Student</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 347,
      sortable: true,
      editable: true,
      renderCell: (params) => {
        const firstLetter = params.row.first_name?.charAt(0).toUpperCase();
        return (
          <Box sx={{ width: "100%", overflow: "hidden", whiteSpace: "normal" }}>
            <Box
              display={"flex"}
              paddingRight={"20px"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
              style={{ cursor: "pointer" }}
              component={"div"}
              onClick={() => {
                setDrawer(true);
                setParamID(params.row.id);
              }}
            >
              <Box
                display={"flex"}
                gap={"8px"}
                flexDirection={"row"}
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    background: "#F4F6FF",
                    borderRadius: "50%",
                    height: "24px",
                    width: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="detail">{firstLetter}</Typography>
                </Box>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="detail"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {params.row.first_name} {params.row.last_name}
                  </Typography>
                  <Typography
                    color="#696969"
                    variant="detail"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {params.row.campus_id}
                  </Typography>
                </div>
              </Box>
              <Box
                display={"flex"}
                gap={"8px"}
                flexDirection={"row"}
                alignItems={"center"}
              ></Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "email",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Email</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 263,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", overflow: "hidden", whiteSpace: "normal" }}>
            <Box style={{ cursor: "pointer" }}>
              <Typography
                variant="detail"
                color="#696969"
                sx={{ whiteSpace: "break-spaces" }}
              >
                {" "}
                {params.row.email}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "phone",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Phone</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 155,
      editable: true,
      sortable: true,
    },
    {
      field: "published",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Enrolment status</Typography>
          <Icons name="descImage" fill="#8E8D8A" width={10} height={12} />
        </Box>
      ),
      width: 185,
      editable: true,
      sortable: true,
      renderCell: (params) => {
        return params.row.enrolmentStatus != "" ? (
          <Box display={"flex"} alignItems={"center"} flexGrow={1}>
            <StatusDropdown
              sx={{ width: "100%" }}
              status={params.row.enrolment_status}
              type={"enrolmentStatus"}
              setToast={setToast}
              fileId={params.row.id}
              timestamp={""}
              onClickCallback={() => console.log("selceted DropDown")}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
    {
      field: "subjects ",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Subjects</Typography>
        </Box>
      ),
      width: 290,
      editable: false,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.row.subjects && params.row.subjects.length > 0 ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            flexGrow={1}
            sx={{ width: "100%", overflowY: "scroll", maxHeight: "52px" }}
          >
            <SubjectCodeDropDown
              codeArray={params.row.subjects}
              stuId={params.row.id}
              setToast={setToast}
            />
          </Box>
        ) : (
          <Icons name="minus" fill="#8E8D8A" width={16} height={16} />
        );
      },
    },
    {
      field: "reset_pass",
      renderHeader: () => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Typography variant="body1">Action</Typography>
        </Box>
      ),
      width: 163,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        const isDisabled = disabledRows.has(params.row.id);
        const isLoading = loadingRows.has(params.row.id);
        return (
          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} /> // Show loader
            ) : (
              <Tooltip placement="right-end" title={isDisabled ? "Please wait before resetting again" : "Reset Password"}>
              <span>
                <Icons
                  onClick={
                    isDisabled
                      ? undefined
                      : () => handleResetStudent(params.row.email, params.row.id)
                  }
                  name="lockReset"
                  fill="#8E8D8A"
                  width={25}
                  height={25}
                  style={{
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    opacity: isDisabled ? 0.5 : 1,
                  }}
                />
              </span>
            </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Typography sx={{ textAlign: "center", marginTop: "40px" }}>
          Loading....
        </Typography>
      ) : (
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              transition: "background-color 0.3s",
              overflowY: "scroll",
              "&:hover": {
                backgroundColor: "#FFF8EC",
              },
              "& .MuiBox-root": {
                overflowX: "hidden",
                flexWrap: "wrap",
              },
            },
            border: "none",
            "& .MuiDataGrid-menuIcon": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "unset",
              color: "#1A1C1F",
              fontSize: 12,
              fontWeight: "600",
            },
            ".MuiDataGrid-columnHeaders": {
              bgcolor: "#F4F6FF",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              minHeight: "44px !important",
              border: "none",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-withBorderColor:focus": {
              outline: "none",
            },
            "& .MuiCheckbox-root": {
              borderRadius: "8px",
            },
            ".MuiDataGrid-row.Mui-selected": {
              background: "#FFF8EC",
            },
            ".css-12xgrea-MuiButtonBase-root-MuiCheckbox-root:hover": {
              background: "none",
            },
            ".MuiDataGrid-row:hover": {
              background: "transparent",
            },
            ".MuiDataGrid-row.Mui-selected:hover": {},
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
              "& .MuiSvgIcon-root": {
                color: "#FFA737",
              },
            },
            "& .MuiDataGrid-withBorderColor:focus-within": {
              outline: "none",
            },
            ".css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover": {
              backgroundColor: "unset !important",
              color: "unset !important",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "400",
              fontSize: "12px",
              color: "#333333",
            },
            ".MuiDataGrid-cellContent": {
              fontWeight: "400",
              fontSize: "12px",
              color: "#696969",
            },
            ".MuiDataGrid-iconButtonContainer": {
              display: "none",
            },
          }}
          rows={stuData}
          columns={columns}
          loading={tableLoading}
          rowCount={rowCountState}
          paginationMode="server"
          rowHeight={44}
          checkboxSelection={false}
          pageSizeOptions={[15, 25, 50, 100]}
          disableRowSelectionOnClick
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          disableColumnMenu
        />
      )}
      {paramID !== null && drawer && (
        <StudentDetailsDrawer
          drawer={drawer}
          setDrawer={setDrawer}
          setParamID={setParamID}
          paramsRowId={paramID}
        />
      )}
      {toast && (
        <ToastMessage
          type={toast.type as "success" | "error"}
          message={toast.message}
          onClose={() => setToast(null)}
          style={{
            top: "85px",
          }}
        />
      )}
    </Box>
  );
};

export default StudentTData;
