import React, { useEffect, useState } from "react";
import { Box, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import { Close } from "../../utils/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { uploadFilesInBatches } from "../../services/api/uploader";
import UploadImg from "../../assets/images/upload.png";

const Uploader = () => {
  const dispatch: AppDispatch = useDispatch();
  const { open, minimized, loading, files } = useSelector((state: RootState) => state.uploader);
  const [error, setError] = useState<string | null>(null);
  // const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files ? Array.from(e.target.files) : [];
    const uniqueFiles = newFiles.filter(
      (file) => !files.some((existingFile) => existingFile.fileName === file.name)
    );

    if (uniqueFiles.length !== newFiles.length) {
      setError("Duplicate files were removed.");
    } else {
      setError(null);
    }

    if (uniqueFiles.length > 0) {
      dispatch(uploadFilesInBatches(uniqueFiles));
    }
  };

  const handleClose = () => {
      window.location.reload();
  };

  // const confirmLeave = () => {
  //   setShowConfirmDialog(false);
  //   dispatch(setOpen(false));
  //   window.location.reload();
  // };

  // const cancelLeave = () => {
  //   setShowConfirmDialog(false);
  // };

  const totalFiles = files.length;
  const totalProgress = totalFiles
    ? Math.round(files.reduce((sum, file) => sum + file.progress, 0) / totalFiles)
    : 0;

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (loading) {
        e.preventDefault();
        e.returnValue = ""; // Standard to show a confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [loading]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
            handleClose();
        }}
        closeAfterTransition
        disableEscapeKeyDown
        slotProps={{
          root: {
            style: minimized
              ? { position: "absolute", left: "auto", top: "980px", right: 0 }
              : {},
          },
          backdrop: {
            style: { display: minimized ? "none" : "block" },
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: minimized ? "auto" : "50%",
            bottom: minimized ? "5%" : "auto",
            right: minimized ? "5%" : "50%",
            width: minimized ? 300 : 610,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            transition: "all 0.3s ease",
            transform: minimized ? "auto" : "translate(50%, -50%)",
            borderRadius: minimized ? "6px" : "24px",
            padding: "0",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: "1px solid #D2D2D0", padding: minimized ? "8px" : "13.5px 28px" }}
          >
            <Typography
              sx={{
                fontSize: minimized ? "18px" : "28px",
                fontWeight: "600",
                lineHeight: "33.6px",
                color: "#333",
              }}
            >
              {minimized ? "File Upload Progress" : "Upload Files"}
            </Typography>
            <Box>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>

          {!minimized ? (
            <Box sx={{ padding: minimized ? "0" : "0px 28px 12px" }}>
              <input
                type="file"
                multiple
                onChange={handleFileSelect}
                style={{ display: "none" }}
                id="file-upload-input"
              />
              <label htmlFor="file-upload-input">
                <Box
                  sx={{
                    mt: 2,
                    height: 136,
                    border: "2px dashed #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "8px",
                    ":hover": { background: "#ffa7371f" },
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <img style={{ marginBottom: "5px" }} src={UploadImg} alt="" />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#333",
                      }}
                    >
                      Upload a file
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#696969",
                      }}
                    >
                      Click to browse, or drag & drop a file here
                    </Typography>
                  </Box>
                </Box>
              </label>

              {error && (
                <Typography color="error" variant="body2" mt={2}>
                  {error}
                </Typography>
              )}
            </Box>
          ) : (
            <Box mt={2}>
              <Typography
                variant="body2"
                color="textSecondary"
                marginBottom={"8px"}
                paddingX={"8px"}
              >
                Uploading {totalFiles} file{totalFiles > 1 ? "s" : ""} - {totalProgress}% complete
              </Typography>
              <LinearProgress variant="determinate" value={totalProgress} />
            </Box>
          )}

          {!minimized && (
            <Box
              mt={2}
              sx={{
                padding: "0 28px",
                maxHeight: "calc(100vh - 320px)",
                overflowY: "scroll",
              }}
            >
              {files.map((file) => (
                <Box key={file.fileId} mb={2}>
                  <Typography variant="body2">{file.fileName}</Typography>
                  <LinearProgress variant="determinate" value={file.progress} />
                  <Typography variant="caption" color="textSecondary">
                    {file.progress}% - {file.status}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      {/* <Dialog open={showConfirmDialog} onClose={cancelLeave}>
        <DialogTitle>Leave Page?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An upload is in progress. Are you sure you want to leave this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelLeave} color="primary">
            Stay
          </Button>
          <Button onClick={confirmLeave} color="secondary" autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default Uploader;
